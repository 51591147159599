<template>
  <div>
    <a-skeleton v-if="leading" :active="true" avatar :paragraph="{ rows: 4 }" />
    <div v-else>
      <div class="card card-body">
        <div class="row justify-content-xl-center">
          <div class="col-xl-12 col-lg-12">
            <a-page-header :title="$t('menu.profile')"></a-page-header>
          </div>
        </div>
        <a-form :form="form" @submit="handleSubmit">
          <fieldset>
            <legend>{{ $t("personnel.infoBase") }}:</legend>
            <div class="row">
              <div class="col-md-3 col-12">
                <a-form-item :label="$t('liste.addFiles')">
                  <a-upload-dragger
                    v-decorator="[
                      'photo',
                      {
                        rules: [
                          {
                            required: false,
                          },
                        ],
                      },
                    ]"
                    :multiple="false"
                    :file-list="fileList"
                    :before-upload="beforeUpload"
                    :customRequest="addTofileList"
                    name="file"
                  >
                    <div>
                      <p class="ant-upload-drag-icon">
                        <a-icon :type="'user'" />
                      </p>
                      <div class="ant-upload-text" style="font-size: 12px">
                        {{ $t("etablissement.clickOrDragToUpload") }}
                      </div>
                    </div>
                  </a-upload-dragger>
                  <span class="subLabel">
                    {{ $t("all.typeNeeded") }}
                  </span>
                </a-form-item>
              </div>

              <div class="col-12 col-md-9"></div>

              <div class="col-md-3 col-12">
                <a-form-item :label="$t('profil.nomUser')">
                  <a-input
                    v-decorator="[
                      'username',
                      {
                        rules: [
                          { required: true, message: $t('requis.username') },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </div>

              <div class="col-md-3 col-12">
                <a-form-item :label="$t('profil.prenom')">
                  <a-input
                    v-decorator="[
                      'firstName',
                      {
                        rules: [
                          { required: false, message: $t('requis.prenom') },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </div>

              <div class="col-md-3 col-12">
                <a-form-item :label="$t('profil.nom')">
                  <a-input
                    v-decorator="[
                      'lastName',
                      {
                        rules: [{ required: false, message: $t('requis.nom') }],
                      },
                    ]"
                  />
                </a-form-item>
              </div>

              <div class="col-md-3 col-12">
                <a-form-item :label="$t('profil.tel')">
                  <a-input
                    v-decorator="[
                      'phone',
                      {
                        rules: [
                          {
                            min: 8,
                            max: 8,
                            message: $t('requis.telephone'),
                          },
                          {
                            validator: checkNotStartingWithZero,
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </div>

              <div class="col-12">
                <a-button type="primary" html-type="submit" :disabled="status">
                  {{ $t("action.enregistrer") }}
                </a-button>
                <a-progress
                  class="mb-4"
                  :stroke-color="{
                    from: '#108ee9',
                    to: '#87d068',
                  }"
                  v-show="uploadPercentage > 0"
                  :percent="uploadPercentage"
                  status="active"
                />
              </div>
            </div>
          </fieldset>
        </a-form>

        <a-form :form="formPassword" @submit="validatePassword" class="mt-5">
          <fieldset>
            <legend>
              {{ $t("action.reset") + " " + $t("login.password") }}:
            </legend>

            <div class="row">
              <div class="col-md-6 col-12">
                <a-form-item :label="$t('login.password')">
                  <a-input
                    type="password"
                    v-decorator="[
                      'password',
                      {
                        rules: [
                          { required: true, message: $t('requis.mdp') },
                          { min: 4, message: $t('requis.carac') },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </div>

              <div class="col-md-6 col-12">
                <a-form-item :label="$t('utilisateur.confirmerMdp')">
                  <a-input
                    type="password"
                    v-decorator="[
                      'confirmedPassword',
                      {
                        rules: [
                          {
                            required: true,
                            message: $t('requis.confirmation'),
                          },
                          {
                            validator: compareToFirstPassword,
                            message: $t('requis.confirmation'),
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </div>

              <div class="col-12">
                <a-button
                  type="primary"
                  html-type="submit"
                  :disabled="statusPassword"
                >
                  {{ $t("action.enregistrer") }}
                </a-button>

                <a-progress
                  class="mb-4"
                  :stroke-color="{
                    from: '#108ee9',
                    to: '#87d068',
                  }"
                  v-show="uploadPercentagePassword > 0"
                  :percent="uploadPercentagePassword"
                  status="active"
                />
              </div>
            </div>
          </fieldset>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/services/axios";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      leading: true,
      imageUrl: "",
      fileList: [],
      userPhoto: null,
      form: this.$form.createForm(this),
      userState: null,
      uploadPercentage: 0,
      status: false,
      uploadPercentagePassword: 0,
      statusPassword: false,
      dbName: null,
    };
  },

  mounted() {},

  methods: {
    ...mapActions(["setAvatar"]),

    addTofileList() {
      this.fileList = [this.userPhoto];
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          const formData = new FormData();
          formData.append("avatar", this.userPhoto);
          formData.append("userName", values.username);
          formData.append(
            "firstName",
            !!values.firstName ? values.firstName : ""
          );
          formData.append("lastName", !!values.lastName ? values.lastName : "");
          formData.append("phone", !!values.phone ? values.phone : "");
          formData.append("dbName", this.dbName);
          this.status = true;
          await apiClient
            .patch("/users/" + this.userState.userName + "/avatar", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                dbName: this.dbName,
              },
              onUploadProgress: (progressEvent) => {
                this.uploadPercentage = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
              },
            })
            .then(({ data }) => {
              this.$store.dispatch("user/setAvatar", data.avatar);
              this.$message.success(this.$t("success.utilisateurMSA"));
            })
            .catch((error) => {
              this.$message.error(this.$t("error.impoModifi"));
            })
            .finally(() => {
              this.uploadPercentage = 0;
              this.status = false;
            });
        }
      });
    },

    validatePassword(e) {
      e.preventDefault();
      this.formPassword.validateFields(async (err, values) => {
        if (!err) {
          this.statusPassword = true;
          await apiClient
            .patch(
              "/users/" + this.userState.userName + "/rest/password",
              values,
              {
                onUploadProgress: (progressEvent) => {
                  this.uploadPercentagePassword = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                },
              }
            )
            .then((data) => {
              this.$message.success(this.$t("success.utilisateurMSA"));
              this.formPassword.setFieldsValue({
                password: "",
                confirmedPassword: "",
              });
            })
            .catch((error) => {
              this.$message.error(this.$t("error.impoModifi"));
            })
            .finally(() => {
              this.uploadPercentagePassword = 0;
              this.statusPassword = false;
            });
        }
      });
    },

    compareToFirstPassword(rule, value, callback) {
      const form = this.formPassword;
      if (value && value !== form.getFieldValue("password")) {
        callback("Two passwords that you enter is inconsistent!");
      } else {
        callback();
      }
    },

    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error(this.$t("error.fichiers"));
      }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error(this.$t("error.image"));
      }
      if (isJpgOrPng && isLt2M) {
        this.$message.success(
          this.$t("success.imageTelecharge", { name: file.name })
        );
        this.userPhoto = file;
      }
      return isJpgOrPng && isLt2M;
    },

    checkNotStartingWithZero(rule, value, callback) {
      if (value && value.charAt(0) === "0") {
        callback("Phone number should not start with zero");
      } else {
        callback();
      }
    },
  },

  watch: {},

  async created() {
    this.formPassword = this.$form.createForm(this, {
      onFieldsChange: (_, changedFields) => {
        this.$emit("change", changedFields);
      },
    });

    this.userState = this.$store.state.user;

    await apiClient.get("/users/" + this.userState.userName).then((result) => {
      this.leading = false;
      setTimeout(() => {
        const data = result.data;
        this.dbName =
          data.buildings.length > 0 ? data.buildings[0].dbName : null;
        this.form.setFieldsValue({
          username: data.userName,
          firstName: data.firstName,
          lastName: data.lastName,
          phone: data.phone,
        });
      }, 10);
    });
  },
};
</script>

<style lang="scss" scoped>
.subLabel {
  color: #797979;
  font-size: smaller;
}
</style>
